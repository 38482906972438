<template>
  <v-card flat width="60%" style="margin: auto" class="signature" height="calc(70vh)">
    <v-card flat width="80%" class="m-auto p-t-36">
      <v-card-text class="text-center">
        <img height="120px" src="../../assets/congratulate.png" />
      </v-card-text>
      <v-card-text class="text-center">
        <span class="bold-f f-s-24" style="color: #8FBAEC">ALL SET {{ personal.firstName }}!</span>
      </v-card-text>
      <v-card-text align="center" class="bold-f p-0"
        >Thank you for doing business with <br />
        {{ portfolio.displayName }}
      </v-card-text>
      <v-card-text class="text-center lighten-2">
        <p>Your application is now undergoing a final review. Once approved you will</p>
        receive an email confirmation and will be funded
        <span class="bold-f">
          {{ new Date(parseInt(loanDetail.effectiveDate)).format('yyyy-MM-dd') }} </span
        >.
        <p>
          If you have any questions you can reach us at <span class="bold-f">{{ tool.formatPhone(portfolio.telephone) }}</span> or
        </p>
        <p class="m-0">
          email us at <span class="bold-f">{{ portfolio.supportEmail }}</span
          >.
        </p>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { DataType, Tool } from '../../js/core'
import { OriginationApi } from '../../api/application'

export default {
  data () {
    return {
      page: DataType.PAGES.SUCCESS,
      personal: {},
      firstName: '',
      cellPhone: '',
      portfolioPhoneNumber: '',
      portfolio: {},
      loanDetail: {},
      tool: new Tool()
    }
  },
  created () {
    this.$store.commit('setCurrentPage', this.page)
    this.$store.commit('setOverlay', false)
    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    this.firstName = this.personal.firstName
    this.cellPhone = this.personal.cellPhone
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    this.portfolio.portfolioPhoneNumber = this.portfolio.telephone
    this.portfolio.portfolioEmail = this.portfolio.email
    const _this = this
    OriginationApi.getLoanById(
      {
        loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        feature: 'PAYMENT_OPTION'
      },
      function (result) {
        _this.loanDetail = result
      },
      function (res) {
        _this.$router.push(_this.route)
      },
      function () {
        _this.error()
      }
    )
  },
  methods: {
    toPhone (mun) {
      if (!mun) return ''
      mun = mun.replace(/\D/g, '')
      if (mun.length > 6) {
        const a = mun.slice(0, 3)
        const b = mun.slice(3, 6)
        const c = mun.slice(6, mun.length)
        mun = '(' + a + ') ' + b + '-' + c
      }
      return mun
    }
  }
}
</script>
